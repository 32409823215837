.imageSlider__slides {
  display: flex;
  position:relative;
  width: 50%;
  height: 50%;
}

.imageSlider__container {
  position: relative
}

.imageSlider__arrow-wrap {
  position: absolute;
  top: 0;
  width: 50%;
  max-width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}