.App {
  text-align: center;
  /* height: 100vh; */
}

div#root {
  min-height: 100vh;
  /*overflow-y: hidden; */
  /* overflow-x: hidden; */
  background-color: #eee;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* body {
  margin: 0% 0% 55px 0%;
} */

/* * :not(div, input) {
  margin: 10px;
  display: block;
} */

script {
  display: none;
}

style {
  display: none;
}

div.footer {
  /* position:absolute; */
  bottom: 10px;
  width: 200px;
}

button.react-calendar__tile--now, button.react-calendar__tile--now.end-calendar.react-calendar__tile:disabled {
  background-color: #1087ff;
  color: #fff;
}

button.react-calendar__tile--hasActive, button.react-calendar__tile:enabled:hover, button.react-calendar__tile--active:enabled:focus, button.end-calendar.react-calendar__tile--hasActive, button.react-calendar__tile--hasActive:enabled:focus {
  background-color: #E68423;
  color: #fff;
}

button.end-calendar, button.end-calendar.react-calendar__tile--hasActive.react-calendar__tile:disabled, button.react-calendar__tile--active, button.react-calendar__tile:disabled.react-calendar__tile--active {
  background-color: #F2A960;
  ;
}

button.react-calendar__tile:disabled.react-calendar__tile--active {
  opacity: 1 !important;
}

button.react-calendar__tile:disabled.react-calendar__tile--active {
  color: white;
}