.mapPlaceSearchForm__container{
    position: absolute;
    left: 10px;
    top: 60px;
    z-index: 100;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}
