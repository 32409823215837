.mapSearchMediaDetail__scrollMediaDetail {
    padding: 1rem 2rem;
    width: 100%;
    height: calc(100vh - 170px);
    overflow-y: scroll;
    grid-template-columns: 50% 50%;
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  
  .mapSearchMediaDetail__scrollMediaDetail::-webkit-scrollbar {
    display: none;
  }
  
  .mapSearchMediaDetail__mediaDetailTitle {
    margin-bottom: 1rem;
  }
  
  .mapSearchMediaDetail__cartDetailButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    box-shadow: 0 -0.5px 4px 0 rgba(0, 0, 0, 0.2);
  }
  