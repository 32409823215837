.appLayoutGroup__appGridLayout {
  height: 100%;

}

.appLayoutGroup__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.appLayoutGroup__verticalBarColumn {
  background-color: #555;
  font-size: 12px;
  border-right: 1px solid  #757575;
  min-height:calc(100vh - 56px);
  position: relative;
}

.appLayoutGroup__verticalBarColumnOpen {
  max-width:20vw;
}

.appLayoutGroup__verticalBarColumnClose {
  max-width:8vw;
}

.appLayoutGroup__navbarAngle {
  cursor: pointer;
  position: absolute;
  right: -21px;
  top: 300px;
}

.appLayoutGroup__campaignLayoutContent {
  background-color: #eee;
  padding: 20px;
  overflow-y: scroll;
  width: 100vw;
  /* position: relative; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.appLayoutGroup__campaignLayoutContentOpen {
  min-width: 60vw;
}

.appLayoutGroup__campaignLayoutContentClose {
  min-width: 84vw;
}

