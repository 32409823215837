.restartMediaForm__appLayoutMessage {
  /* text-align: center; */
  bottom: 100px;
  position: absolute !important;
  z-index: 2;
  width: 60vw;
  left: 20vw;
}

.restartMediaForm__mediaDetailColumn {
  /* padding: 0 20px 0 20px; */
  /* margin: 40px 60px 0 0; */
  margin-top: 2rem;
}

.restartMediaForm__calenarLetter {
  font-weight: 700;
}
