$primary-color: #e68423;

$breakpoints: (
  'lg': 'screen and (min-width: 1024px)',
  'md': 'screen and (max-width:1024px)',
  'sm': 'screen and (max-width:480px)',
);

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mq($breakpoint: sm) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mq($breakpoint: lg) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin white-gradient {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.landingPage__appeal-container {
  width: 45%;
  @include mq(md) {
    width: 100%;
    margin-bottom: 4rem;
  }
  &__title {
    background-color: $primary-color;
    color: white;
    font-size: 1.75rem;
    font-weight: 800;
    border-radius: 0.5rem;
    padding: 1.5rem;
    letter-spacing: 0.25rem;
    margin-bottom: 4rem;
    text-align: center;
    span {
      font-size: 2rem;
    }
    @include mq(md) {
      letter-spacing: 0;
      padding: 1.25rem;
    }
  }
  &__detail {
    font-size: 1.25rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
    min-height: 105px;
  }
  &__image {
    // background-color: white;
    border-radius: 1rem;
    img {
      width: 100%;
    }
  }
}

.landingPage__cta-container {
  padding: 8rem;
  text-align: center;
  background-color: #2a2e42;
  color: white;
  @include mq(sm) {
    padding: 2rem 1rem;
  }
  &__title {
    margin-bottom: 4rem;
    font-size: 2rem;
    letter-spacing: 0.25rem;
    &--force {
      font-size: 2.5rem;
      margin-right: 0.25rem;
    }
    span {
      display: inline-block;
    }
  }
  &__button {
    display: inline-block;
    padding: 1.5rem 2rem;
    border-radius: 2.25rem;
    background-color: $primary-color;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: white;
    transition: all 0.2s ease-in;
    border-bottom: 2px solid $primary-color;
    border-left: 2px solid $primary-color;
    border-right: 2px solid $primary-color;
    border-top: 2px solid $primary-color;
    box-shadow: 0px 4px 12px -1px rgba(63, 145, 220, 0.12);
    &:hover,
    &:focus {
      background-color: #fff;
      border-bottom: 2px solid $primary-color;
      border-left: 2px solid $primary-color;
      border-right: 2px solid $primary-color;
      border-top: 2px solid $primary-color;
      transform: translate(0px, -6px);
      color: $primary-color;
    }
  }
}

.landingPage__first-view {
  position: relative;
  padding: 8rem;
  max-width: 1440px;
  margin: 0 auto;
  height: 640px;
  @include mq(md) {
    padding: 2rem 4rem;
    height: fit-content;
    @include mq(sm) {
      padding: 2rem 1rem;
    }
  }
  &__concept {
    margin-bottom: 2rem;
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.5;
    letter-spacing: 0.5rem;
    color: white;
    max-width: 720px;
    span {
      display: inline-block;
    }
    @include mq(md) {
      letter-spacing: 0;
      @include mq(sm) {
        font-size: 2.125rem;
      }
    }
  }
  &__message {
    font-size: 1.25rem;
    line-height: 2rem;
    letter-spacing: 0.25rem;
    margin-bottom: 4rem;
    color: white;
    max-width: 480px;
    span {
      display: inline-block;
    }
    @include mq(sm) {
      margin-bottom: 2rem;
    }
  }
  &__button {
    display: inline-block;
    padding: 1.5rem 2rem;
    border-radius: 2.25rem;
    background-color: $primary-color;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: white;
    transition: all 0.2s ease-in;
    border-bottom: 2px solid $primary-color;
    border-left: 2px solid $primary-color;
    border-right: 2px solid $primary-color;
    border-top: 2px solid $primary-color;
    box-shadow: 0px 4px 12px -1px rgba(63, 145, 220, 0.12);
    &:hover,
    &:focus {
      background-color: #fff;
      border-bottom: 2px solid $primary-color;
      border-left: 2px solid $primary-color;
      border-right: 2px solid $primary-color;
      border-top: 2px solid $primary-color;
      transform: translate(0px, -6px);
      color: $primary-color;
    }
    @include mq(md) {
      display: block;
      padding: 1rem 1.5rem;
      margin-left: 8rem;
      margin-right: 8rem;
      text-align: center;
      margin-bottom: 4rem;
      @include mq(sm) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
  &__image {
    position: absolute;
    width: 36rem;
    right: -2rem;
    top: 12rem;
    @include mq(md) {
      position: relative;
      width: 80%;
      margin: 0 10% 2rem;
      right: 0;
      top: 0;
    }
  }
}

.landingPage__issue-container {
  padding: 8rem;
  background-color: white;
  @include mq(md) {
    padding: 8rem 2rem;
    @include mq(sm) {
      padding: 2rem 1rem;
    }
  }
  span {
    display: inline-block;
  }
  &__message {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: 0.25rem;
    color: #e68423;
    span {
      display: inline-block;
    }
    @include mq(sm) {
      text-align: left;
      font-size: 2rem;
      letter-spacing: 0;
    }
  }
}

.landingPage__lpHidden {
  height: 100vh;
  overflow: scroll;
  /* max-width: 1360px; */
}

.landingPage__lpContainer {
  position: relative;
}
.landingPage__imageWrapper {
  position: relative;
}

.landingPage__lpContainerText {
  position: absolute;
  top: 10vh;
  left: 5vw;
  height: 80vh;
}

.landingPage__lpHeader {
  color: #fff;
  font-size: 36px;
  line-height: 60px;
}

.landingPage__lpNote {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  margin-top: 20vh;
  margin-bottom: 5vh;
}

.landingPage__lpButtons {
  position: absolute;
  bottom: 10vh;
}

.landingPage__media-list {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: white;
  &__title {
    text-align: center;
    font-size: 2rem;
    color: $primary-color;
    margin-bottom: 4rem;
  }
  &__body {
    display: flex;
    margin-bottom: 4rem;
    width: 100%;
    overflow: scroll;
    padding-left: 8rem;
    padding-right: 8rem;
    @include mq(md) {
      display: block;
      padding: 2rem 1rem;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__message {
    text-align: center;
    color: #3d456e;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.25rem;
    @include mq(md) {
      text-align: left;
      padding: 0 8rem;
      @include mq(sm) {
        padding: 0 1rem;
      }
    }
  }
  &__button {
    display: inline-block;
    padding: 1rem 1.5rem;
    border-radius: 2.25rem;
    border-color: $primary-color;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: $primary-color;
    transition: all 0.2s ease-in;
    border-bottom: 2px solid $primary-color;
    border-left: 2px solid $primary-color;
    border-right: 2px solid $primary-color;
    border-top: 2px solid $primary-color;
    box-shadow: 0px 4px 12px -1px rgba(63, 145, 220, 0.12);
    &:hover,
    &:focus {
      background-color: #fff;
      border-bottom: 2px solid $primary-color;
      border-left: 2px solid $primary-color;
      border-right: 2px solid $primary-color;
      border-top: 2px solid $primary-color;
      // transform: translate(0px, -6px);
      color: white;
      background-color: $primary-color;
    }
    @include mq(md) {
      display: block;
      padding: 1rem 1.5rem;
      margin-left: 8rem;
      margin-right: 8rem;
      text-align: center;
      margin-bottom: 4rem;
      @include mq(sm) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
  &__btn-wrapper {
    text-align: center;
    padding: 2rem 0 0;
  }
}

.landingPage__medium-container {
  margin-right: 10rem;
  @include mq(md) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    max-width: 360px;
  }
  &__image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border: 2px solid black;
    @include mq(md) {
      width: 100%;
    }
  }
  &__title {
    font-size: 1.25rem;
    font-weight: 700;
  }
  &__type {
    display: inline-block;
    padding: 0.125rem 0.25rem;
    border-radius: 2px;
    background-color: #f3f3f3;
    font-size: 0.75rem;
    color: #747474;
  }
  &__detail {
    font-size: 0.875rem;
    margin: 0.25rem;
  }
  &__category {
    font-size: 0.875rem;
    color: #747474;
  }
}

.landingPage__ooh-merit {
  padding: 12rem 10rem;
  color: white;
  background: url('../../../assets/pictures/lp_issue_background.png');
  width: 100%;
  background-size: cover;
  background-position: 50%;
  position: relative;
  @include mq(md) {
    padding: 6rem 4rem;
    @include mq(sm) {
      padding: 1rem 2rem;
    }
  }
  &::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &__title {
    position: relative;
    margin-bottom: 4rem;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 4rem;
    letter-spacing: 0.25rem;
    z-index: 1;
    span {
      display: inline-block;
    }
    @include mq(sm) {
      font-size: 2rem;
      line-height: 3rem;
      letter-spacing: 0;
      margin-bottom: 2rem;
    }
  }
  &__body {
    position: relative;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    line-height: 3rem;
    letter-spacing: 0.25rem;
    z-index: 1;
    @include mq(sm) {
      font-size: 1rem;
      line-height: 2rem;
      letter-spacing: 0;
    }
  }
}

.landingPage__ooh-problem {
  padding: 6rem 8rem;
  text-align: center;
  background-color: #2a2e42;
  color: white;
  @include mq(sm) {
    padding: 1rem 2rem;
  }
  &__title {
    font-size: 2.5rem;
    letter-spacing: 0.25rem;
    text-align: center;
    margin-bottom: 4rem;
    @include mq(sm) {
      letter-spacing: 0;
      font-size: 1.75rem;
      margin-bottom: 2rem;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    @include mq(md) {
      display: block;
    }
  }
  &__icon {
    font-size: 3rem;
  }
}

.landingPage__problem-container {
  width: 30%;
  padding: 2rem;
  border: 4px white solid;
  border-radius: 1rem;
  @include mq(md) {
    width: 100%;
    margin-bottom: 2rem;
    padding: 2rem 1rem;
  }
  &__icon {
    text-align: center;
    font-size: 5rem;
  }
  &__title {
    text-align: center;
    font-size: 2rem;
    color: $primary-color;
  }
  &__detail {
    text-align: left;
    font-size: 1.25rem;
    line-height: 2.5rem;
    @include mq(sm) {
      font-size: 1rem;
      line-height: 2rem;
    }
  }
}

.landingPage__question {
  padding: 4rem 8rem;
  @include mq(sm) {
    width: 100%;
    padding: 2rem 1rem;
  }
  &__title {
    text-align: center;
    font-size: 2rem;
    color: $primary-color;
    margin-bottom: 4rem;
  }
}

.landingPage__question-container {
  padding: 3rem 4rem;
  background-color: white;
  border-radius: 0.5rem;
  margin-bottom: 4rem;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sm) {
    width: 100%;
    padding: 2rem 3rem;
  }
  &__question {
    font-size: 1.5rem;
    font-weight: 800;
    letter-spacing: 0.25rem;
    line-height: 2.5rem;
    &::before {
      content: 'Q.';
      color: $primary-color;
      font-weight: 800;
      margin-right: 1rem;
    }
  }
  &__answer {
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #747474;
    &::before {
      content: 'A.';
      color: $primary-color;
      font-weight: 800;
      margin-right: 1rem;
    }
  }
}

.landingPage__section1 {
  background: linear-gradient(90deg, #30456e, #658ede);
}

.landingPage__service-appeal {
  background: url('../../../assets/pictures/lp_appeal_background.png');
  background-size: cover;
  padding: 4rem 8rem 8rem;
  @include mq(md) {
    width: 100%;
    padding: 2rem 8rem;
    @include mq(sm) {
      padding: 2rem 1rem;
    }
  }
  &__title {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 800;
    text-align: center;
    letter-spacing: 0.25rem;
    margin-bottom: 4rem;
    color: white;
    margin-bottom: 4rem;
    @include mq(md) {
      font-size: 2rem;
      text-align: left;
      margin-bottom: 2rem;
    }
    span {
      font-size: 3rem;
      margin-right: 0.5rem;
      @include mq(md) {
        font-size: 2.5rem;
      }
    }
  }
  &__body {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    color: white;
    @include mq(md) {
      display: block;
    }
  }
}

.slider {
  @include mq(lg) {
    background: white;
    overflow: scroll;
    position: relative;
    &::before,
    &::after {
      @include white-gradient;
      content: '';
      height: 100%;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .landingPage__slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(250px * 14);
    }
  }
}

.landingPage__solution-container {
  background-color: $primary-color;
  color: white;
  border-radius: 1rem;
  padding: 2rem 4rem;
  margin: 0 auto -14rem;
  max-width: 980px;
  @include mq(sm) {
    padding: 2rem 1rem;
    margin: 0 -2rem -16rem -2rem;
    border-radius: 0;
  }
  span {
    display: inline-block;
  }
  &__title {
    font-size: 2.5rem;
    font-weight: 600;
    &--name1 {
      margin-left: 1rem;
      font-size: 3.5rem;
      letter-spacing: 0.25rem;
    }
    &--name2 {
      font-size: 1.5rem;
    }
  }
  &__detail {
    font-size: 1.5rem;
    letter-spacing: 0.25rem;
    line-height: 3rem;
    span {
      display: inline-block;
    }
    @include mq(sm) {
      font-size: 1rem;
      line-height: 2rem;
      letter-spacing: 0;
    }
  }
  &__icon {
    margin-left: 2rem;
    font-size: 2rem;
  }
}

.landingPage__usage-title {
  position: relative;
  padding: 1.5rem;
  text-align: center;
  background-color: #5cbbcf;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.25rem;
  margin-bottom: -10rem;
  z-index: 1;
  @include mq(md) {
    margin-bottom: 0;
    line-height: 4rem;
  }
  span {
    font-size: 2.5rem;
    margin-right: 0.25rem;
  }
}

.landingPage__usage1 {
  height: 50rem;
  max-width: 1440px;
  margin: auto;
  &__main {
    padding: 16rem 8rem 2rem;
    width: 50%;
    float: left;
    background-color: white;
    @include mq(md) {
      width: 100%;
      padding: 2rem 1rem;
    }
  }
  &__image {
    width: 50%;
    float: right;
    padding: 16rem 8rem 2rem;
    background-color: #3d456e;
    @include mq(md) {
      width: 100%;
      padding: 4rem 8rem;
      @include mq(sm) {
        padding: 2rem 1rem;
      }
    }
    img {
      width: 100%;
    }
  }
}

.landingPage__usage2 {
  height: 42rem;
  max-width: 1440px;
  margin: auto;
  &__main {
    padding: 8rem 8rem 2rem;
    width: 50%;
    float: right;
    background-color: white;
    @include mq(md) {
      width: 100%;
      padding: 2rem 1rem;
    }
  }
  &__image {
    width: 50%;
    float: left;
    padding: 8rem 8rem 2rem;
    background-color: #3d456e;
    @include mq(md) {
      width: 100%;
      padding: 4rem 8rem;
      @include mq(sm) {
        padding: 2rem 1rem;
      }
    }
    img {
      width: 100%;
    }
  }
}

.landingPage__usage3 {
  height: 40rem;
  max-width: 1440px;
  margin: auto;
  @include mq(md) {
    height: fit-content;
  }
  &__main {
    padding: 8rem 8rem 2rem;
    width: 50%;
    height: 100%;
    float: left;
    background-color: #3d456e;
    @include mq(md) {
      width: 100%;
      padding: 2rem 1rem;
      background-color: white;
      color: black;
    }
  }
  &__image {
    width: 50%;
    height: 100%;
    float: right;
    padding: 8rem 8rem 2rem;
    background-color: #3d456e;
    @include mq(md) {
      width: 100%;
      height: fit-content;
      padding: 4rem 8rem;
      @include mq(sm) {
        padding: 2rem 1rem;
      }
    }
    img {
      width: 100%;
    }
  }
}

.landingPage__usage-description {
  &__num {
    color: $primary-color;
    font-size: 5rem;
  }
  &__title {
    font-size: 2rem;
    line-height: 3rem;
    span {
      display: inline-block;
    }
    &--white {
      font-size: 2rem;
      line-height: 3rem;
      color: white;
      @include mq(md) {
        color: black;
      }
    }
  }
  &__detail {
    line-height: 2rem;
    line-height: 2.5rem;
    color: #707070;
    &--white {
      color: white;
      @include mq(md) {
        color: #707070;
      }
    }
  }
}
