.edit__editCartGrid {
  display: grid;
  margin: -20px;
  grid-template-columns:420px 1fr;
  grid-template-rows: 1fr;
  height: calc(100vh - 56px);
  width: calc(100% + 40px);
  overflow: hidden;
}

.edit__editCartTabs {
  /* max-width: 40rem; */
  /* width: 30vw; */
  /* min-width: 20vw; */
  padding: 20px 20px 60px;
  background-color: white;
  box-shadow: 0 0 .5rem rgba(0,0,0,.3);
  overflow: scroll;
}

.edit__editCartTabs::-webkit-scrollbar {
  display: none;
}

.edit__editCartTabsOpen {
  /* width: 30vw; */
}

.edit__editCartTabsClose {
  /* width: 30vw; */
}

.edit__editCartMap {
  /* max-width: 55vw; */
  /* min-width: 40vw; */
  /* width: 100%; */
  overflow: hidden;
  height: calc(100vh - 56px);
}

.edit__editCartMapOpen {
  width: 100%;
  /* height: 100%; */
}

.edit__editCartMapClose {
  width: 100%;
  /* height: 100% */
}

.edit__closeDetailButton {
  cursor: pointer;
  position: absolute;
  left: -43px;
  top: 50px;
}

.edit__openDetailButton {
  cursor: pointer;
  position: absolute;
  right: -3px;
  top: 106px;
}

#edit__showMediaDetail {
  width: 440px;
  background: #fff;
  position: absolute;
  right: 0px;
}