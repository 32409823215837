.mediaImpressionTable__mediaDetailEffect {
  display: flex;
  flex-wrap: wrap;
  table-layout: fixed;
  margin: 2rem 0;
  border: 1px solid rgba(34, 36, 38, 0.1);
  border-collapse: collapse;
}

.mediaImpressionTable__mediaDetailEffect > tr {
  width: 100%;
}

.mediaImpressionTable__mediaDetailEffectItem {
  display: inline-block;
  width: 50%;
  padding: .25rem 0 .25rem 1rem;
  border: 1px solid rgba(34, 36, 38, 0.1);

}
.mediaImpressionTable__mediaDetailEffectItemTitle {
  font-size: .75rem;
  line-height: 1rem;
  color: #777;
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.mediaImpressionTable__mediaDetailEffectItemValue {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 800;
  margin-left: 1rem;
}