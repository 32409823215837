.appLayoutOwner__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.appLayoutOwner__appGridContent {
  min-height:90vh;
}

.appLayoutOwner__appGridFooter {
  position: relative;
}