.cartTabFooter__cartTabFooter {
  /* width: 100%; */
  width: 420px;
  margin-left: -20px;
  margin-right: -20px;
  bottom: 0;
  padding: -20px 0;
  position: absolute;
  display: flex;
  box-shadow: 0 -.5px 4px 0 rgba(0,0,0,.2);
  z-index: 333;
}

.cartTabFooter__cartTabFooterItem {
  background-color: white;
  border-left: 1px solid #E5E9EC;
  border-top: 1px solid #E5E9EC;
  width: 100%;
  padding: .5rem ;
}
.cartTabFooter__cartTabFooterSmall {
  font-size: .625rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 300;
}
.cartTabFooter__cartTabFooterNumber {
  font-weight: 800;
  font-size: .875rem;
  margin-left: .5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}