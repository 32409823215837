.group__dashboard__groupDashboardScroll {
  min-height: calc(100vh - 128px);
  overflow-y: auto;
}

.group__dashboard__groupDashboardButtons {
  position: sticky !important;
  top: 0;
  z-index: 2;
  border-bottom: solid 1px #ccc;
  background-color: #eee;
}