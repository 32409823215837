/*TODO:@~の場所の修正が必要確認する*/

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 0.1s;
}

  /* pinの下のかげ */
.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 7px;
  top: -18px;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
  /* pinの下の影からでている衝撃波 */
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
      /* 衝撃波の発生地点を調整できる */
  margin: -13px 0 0 -20px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #ff9800;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

div.mapBaloon {
  color: black;
  min-width: 150px;
  z-index: 11;
  position: absolute;
  left: calc(50% - 82px);
  display: flex;
  background-color: #fff;
  border: 1px solid #000;
  padding: .5rem;
  bottom: 20px;
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-25px);
  cursor: pointer;
}

button.mapBaloonClose {
    /* color: black; */
  z-index: 12;
  position: absolute;
  cursor: pointer;
  left: -10px;
  top: -10px;
    /* display: flex; */
  background-color: #eee;
    /* visibility: visible; */
    /* cursor: pointer; */
  border: 1px solid #000;
    /* bottom: 100%; */
    /* -webkit-transform: translateY(-100px); */
    /* -ms-transform: translateY(-8px); */
    /* transform: translateY(-80px); */
    /* transform: translateX(80px); */
}

div.mapInfo {
  color: black;
  min-width: 150px;
  z-index: 1000;
  position: absolute;
  left: calc(50% - 82px);
  font-size: 1rem;
  display: flex;
  background-color: #fff;
  border: 1px solid #000;
  padding: .5rem;
  bottom: 20px;
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-25px);
}

.bubbleAtCartFocused {
  position: relative;
  display: inline-block;
  text-align: center;
  color:#FFF;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  font-weight: 900;
  background-color: #DB2828;
  border-radius: 5px;
  left: -38px;
  bottom: 43px;
  z-index: 15;
  transition: all .3s ease;
}

.bubbleAtCartFocused::before {
  content: '';
  position: absolute;
  display: block;
  z-index: 15;
  border-style: solid;
  border-color: #DB2828 transparent;
  border-width: 10px 10px 0 0;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
}

.bubbleAtCart {
  position: relative;
  display: inline-block;
  text-align: center;
  color:#FFF;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: #DB2828;
  border-radius: 5px;
  left: -38px;
  bottom: 43px;
  z-index: 14;
  transition: all .3s ease;
}
.bubbleAtCart::before {
  content: '';
  position: absolute;
  display: block;
  z-index: 14;
  border-style: solid;
  border-color: #DB2828 transparent;
  border-width: 10px 10px 0 0;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
}

.bubble06 {
  position: relative;
  display: inline-block;
  text-align: center;
  color:#FFF;
  padding: .5rem 1rem;
  background-color: #f39800;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 5px;
  left: -38px;
  bottom: 43px;
  z-index: 12;
  transition: all .3s ease;
}
.bubble06:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 12;
  border-style: solid;
  border-color: #f39800 transparent;
  border-width: 10px 10px 0 0;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
}
.bubble07 {
  position: relative;
  display: inline-block;
  text-align: center;
  color:#FFF;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  font-weight: 900;
  background-color: #5cBBcf;
  border-radius: 5px;
  left: -38px;
  bottom: 43px;
  z-index: 15;
  transition: all .3s ease;
}
.bubble07:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 15;
  border-style: solid;
  border-color: #5cBBcf transparent;
  border-width: 10px 10px 0 0;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
}
/* .bubble07:hover{
  z-index: 1;
} */

.bubble08 {
  position: relative;
  display: inline-block;
  text-align: center;
  color:#FFF;
  padding: .5rem 1rem;
  background-color: #f39800;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 5px;
  left: -38px;
  bottom: 43px;
  z-index: 12;
  transition: all .3s ease;
}

.bubble09 {
  position: relative;
  display: inline-block;
  text-align: center;
  color:#FFF;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  font-weight: 900;
  background-color: #5cBBcf;
  border-radius: 5px;
  left: -38px;
  bottom: 43px;
  z-index: 15;
  transition: all .3s ease;
}

.bubble10 {
  position: relative;
  display: inline-block;
  text-align: center;
  color:#FFF;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  font-weight: 900;
  background-color: #64cf5c;
  border-radius: 5px;
  left: -38px;
  bottom: 43px;
  z-index: 15;
  transition: all .3s ease;
}

.bubble11 {
  position: relative;
  display: inline-block;
  text-align: center;
  color:#FFF;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  font-weight: 900;
  background-color: #64cf5c;
  border-radius: 5px;
  left: -38px;
  bottom: 43px;
  z-index: 15;
  transition: all .3s ease;
}
.bubble11:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 15;
  border-style: solid;
  border-color: #64cf5c transparent;
  border-width: 10px 10px 0 0;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
}