.appLayoutAdmin__appAdminLayout {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.appLayoutAdmin__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.appLayoutAdmin__appGridContent {
  min-height:90vh;
}

.appLayoutAdmin__appGridFooter {
  position: relative;
}