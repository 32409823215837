.manageMediaDetail__mediaDetailTable {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}
.manageMediaDetail__mediaDetailTable tr {
  border-bottom: solid 7px white;
  font-size: 0.875rem;
  text-align: left;
}
.manageMediaDetail__mediaDetailTable tr:last-child {
  border-bottom: none;
}
.manageMediaDetail__mediaDetailTable th {
  position: relative;
  text-align: left;
  width: 40%;
  background-color: #52c2d0;
  color: white;
}
.manageMediaDetail__mediaDetailTable th:after {
  display: block;
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  top: calc(50% - 10px);
  right: -10px;
}
.manageMediaDetail__mediaDetailTable td {
  text-align: left;
  width: 60%;
  background-color: #eee;
  font-weight: 600;
}
.manageMediaDetail__mediaDetailTable td,
th {
  padding: 0.75rem 1rem;
}
