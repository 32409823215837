.contact__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.contact__appGridContent {
  min-height:90vh;
}

.contact__appGridFooter {
  position: relative;
}