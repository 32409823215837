.cartContentTab__scrollCartTab {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
  white-space: normal;
}

.cartContentTab__scrollCartTab::-webkit-scrollbar {
  display: none;
}

.cartContentTab__mediaCardDefault {
  background-color: white;
  border-radius: .5rem;
  border: solid 2px #ddd;
  cursor: pointer;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.cartContentTab__mediaCardSelected {
  border: solid 2px orange;
}
