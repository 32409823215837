.media__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.media__appGridContent {
  min-height: 90vh;
}

.media__mediaSegment {
  width: 75%;
  margin: auto;
}

.media__mediaDetailTitle {
  margin-bottom: 1rem;
}
