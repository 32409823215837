.mediaTop__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.mediaTop__image-link {
  transition: all 0.2s;
}

.mediaTop__image-link:hover {
  opacity: 0.7;
}

.mediaTop__type {
  text-align: left;
  display: inline-block;
  background-color: #f2711c;
  color: white;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  border-radius: 0.875rem;
  font-weight: 800;
  margin-bottom: 0.25rem;
}
.mediaTop__post-text {
  padding: 5px 10px 10px;
}
.mediaTop__post-text h2 {
  width: fit-content;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.mediaTop__post-text p {
  font-size: 1rem;
  font-weight: 700;
}
.mediaTop__thumb {
  width: 100%;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
}
.mediaTop__thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}
.mediaTop__post {
  width: calc(100% / 3 - 60px);
  text-align: left;
  margin: 30px;
  text-decoration: none;
  color: #111;
}
.mediaTop__posts {
  max-width: 1080px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.mediaTop__latest-posts {
  padding: 2rem 0 6rem;
}
