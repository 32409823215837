.mediaCardMapSearch__mediaCardCard {
    grid-template-columns: 140px 1fr 80px;
    grid-template-rows: 2.5rem 2rem 2rem 2rem 2rem;
  }
  
  .mediaCardMapSearch__mediaCardHeader {
    line-height: 2rem;
    padding: 5px;
    border-bottom: solid 1px #E5E9EC;
  }
  
  .mediaCardMapSearch__mediaCardHeader > h4 {
    overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .mediaCardMapSearch__mediaCardHeader > i {
    margin-top: -.5rem !important;
  }
  
  .mediaCardMapSearch__mediaCardItemTitle {
    padding-left: .5rem;
    color: #707070;
    font-size: .875rem;
    line-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .mediaCardMapSearch__mediaCardColumn {
    text-align: right;
    padding-right: 10px;
  }
  
  .mediaCardMapSearch__mediaCardItemValue {
    font-size: 1rem;
    line-height: 2rem;
  }
  
  
  
  .mediaCardMapSearch__mediaCardCard {
    grid-template-columns: 140px 1fr 80px;
    grid-template-rows: 2.5rem 2rem 2rem 2rem 2rem;
  }
  
  .mediaCardMapSearch__mediaCardColumn {
    text-align: right;
    padding-right: 10px;
  }
  .mediaCardMapSearch__mediaCardItemTitle {
    padding-left: .5rem;
    color: #707070;
    font-size: .875rem;
    line-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .mediaCardMapSearch__mediaCardItemValue {
    font-size: 1rem;
    line-height: 2rem;
  }
  .mediaCardMapSearch__mediaCardItemValueSearch {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .mediaCardMapSearch__mediaCardHeader {
    line-height: 2rem;
    padding: 5px;
    border-bottom: solid 1px #E5E9EC;
  }
  .mediaCardMapSearch__mediaCardHeaderSearch {
    line-height: 2rem;
    padding: 5px;
    text-align: left;
  }
  .mediaCardMapSearch__mediaCardAddressSearch {
    font-size: 0.9rem;
    color: #707070;
    line-height: 2rem;
    padding: 0px 5px;
    text-align: left;
  }
  .mediaCardMapSearch__mediaCardHeader > h4 {
    overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .mediaCardMapSearch__mediaCardHeader > i {
    margin-top: -.5rem !important;
  }
  .mediaCardMapSearch__accountImageCircle {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  
  .mediaCardMapSearch__accountImageCircle img {
    border-radius: 50%;
    border: solid 1px #ddd;
    object-fit: cover;
  }
  
  
  