.cartTabs__cartHeaderWrapper {
  z-index: 999;
  padding: 20px;
  position: relative;
  background-color: white;
  width: 420px;
  margin-top: -19px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 1rem;
  box-shadow: 0 .5px 4px rgba(0,0,0,.2);
}