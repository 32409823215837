.signUpForm__loginFormNote {
    font-size: 12px;
    color: #444;
}

.signUpForm__signinFormLabel {
    display:inline;
}

.signUpForm__requiredMark {
    color: #ff0000;
    font-size: 10px;
    vertical-align: top;
    display:inline;
}

.signUpForm__signinFormCheckbox {
    display:inline;
}

.signUpForm__signInFormLink {
    color: #f57c00;
}

.signUpForm__signInFormLink:hover {
    color: #f57c00;
}