.mediaCardSearch__mediaCardCard {
  grid-template-columns: 140px 1fr 80px;
  grid-template-rows: 2.5rem 2rem 2rem 2rem 2rem;
}

.mediaCardSearch__mediaCardHeaderSearch {
  line-height: 2rem;
  padding: 5px;
  text-align: left;
}

.mediaCardSearch__mediaCardAddressSearch {
  font-size: 0.9rem;
  color: #707070;
  line-height: 2rem;
  padding: 0px 5px;
  text-align: left;
}

.mediaCardSearch__mediaCardItemTitle {
  padding-left: .5rem;
  color: #707070;
  font-size: .875rem;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mediaCardSearch__mediaCardColumn {
  text-align: right;
  padding-right: 10px;
}

.mediaCardSearch__mediaCardItemValueSearch {
  font-size: 1.5rem;
  line-height: 2rem;
}

.mediaCardSearch__mediaCardItemTitle {
  padding-left: .5rem;
  color: #707070;
  font-size: .875rem;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}