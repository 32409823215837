.submitContent__campaignListTableContainer {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.submitContent__campaignListTable {
  /* display: block;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; */
  width: 100%;
}

.submitContent__input-for-file[type='file'] {
  display: none;
}
