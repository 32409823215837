.mediaSearch__scrollSearchTab {
  padding: 20px 0 40px;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
  white-space: normal;
}

.mediaSearch__scrollSearchTab::-webkit-scrollbar {
  display: none;
}

.mediaSearch__mediaCardDefault {
  background-color: white;
  border-radius: 0.5rem;
  border: solid 2px #ddd;
  cursor: pointer;
  margin-bottom: 2.5rem;
  overflow: hidden;
}

.mediaSearch__appGridContent {
  min-height: 90vh;
}

.mediaSearch__appGridFooter {
  position: relative;
}

.mediaSearch__mediaCardHovered {
  /* background-color: #eee; */
}

.mediaSearch__mediaCardSelected {
  border: solid 2px orange;
}
