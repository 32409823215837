.verticalBarCart__verticalBarHeader {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  border-bottom: solid 1px #757575;
}

.verticalBarCart__verticalBarHeaderClose {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.verticalBarCart__verticalBarHeader:hover {
  background-color: #333;
}

.verticalBarCart__verticalBarHeader:active {
  background-color: #333;
}

.verticalBarCart__verticalBarRow {
  background-color: #555;
  color: #fff;
  cursor: pointer;
  padding: 8px 16px 8px 16px;
}

.verticalBarCart__verticalBarRowClose {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.verticalBarCart__verticalBarRow:hover {
  background-color: #333;
}

/* .verticalBarCart__verticalBarRow:active {
  background-color: #333;
} */

.verticalBarCart__verticalBarRowSelected {
  background-color: #333;
}

.verticalBarCart__verticalBarButton {
  position: relative;
  /* bottom: 20px; */
  /* margin-bottom: 20px; */
  left: calc(50% - 30px);
}

.verticalBarCart__addButton {
  font-size: 12px !important;
  white-space: nowrap;
}