.cartSearchTab__scrollSearchTab {
  padding: 20px 0 40px;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
  white-space: normal;
}

.cartSearchTab__scrollSearchTab::-webkit-scrollbar {
  display: none;
}

.cartSearchTab__mediaCardDefault {
  background-color: white;
  border-radius: .5rem;
  border: solid 2px #ddd;
  cursor: pointer;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.cartSearchTab__mediaCardSelected {
  border: solid 2px orange;
}