.confirmCartContent__campaignListTableContainer {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.confirmCartContent__campaignListTable {
  /* display: block;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; */
  width: 100%;
}

.confirmCartContent__cartSummaryText {
  font-size: .875rem;
}