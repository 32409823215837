.signInForm__loginFormNote {
    font-size: 12px;
    color: #444;
}

.signInForm__passwordResetLink {
    color: #ff9800;
    cursor: pointer;
    text-align: center;
}

.signInForm__passwordResetLink:hover {
    text-decoration: underline;
}

.signInForm__passwordResetLink:active {
    color: #f57c00;
}