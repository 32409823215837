

.appLayoutGroupEdit__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.appLayoutGroupEdit__verticalBarColumn {
  background-color: #555;
  width: 100%;
  font-size: 12px;
  height: calc(100vh - 55px);
  overflow-y: scroll;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
  white-space: normal;      /* Firefox 対応 */
}

/* Chrome, Safari 対応 */
.appLayoutGroupEdit__verticalBarColumn::-webkit-scrollbar {
  display:none;
}

.appLayoutGroupEdit__navbarAngleOpen {
  /* TODO:css変数の管理 */
  /* アイコンのサイズを表す変数 */
  --iconHeight: 36px;
  --iconWidth: 36px;
  /* ナビゲーターバーの高さを表す変数 */
  --navBarHeight: 55px;
  cursor: pointer;
  position: absolute;
  left: calc(10vw - var(--iconWidth)/2);
  bottom: calc((100vh - var(--navBarHeight))/2 - var(--iconHeight)/2);
}

.appLayoutGroupEdit__navbarAngleClosed {
  /* アイコンのサイズを表す変数 */
  --iconHeight: 36px;
  --iconWidth: 36px;
  /* ナビゲーターバーの高さを表す変数 */
  --navBarHeight: 55px;
  cursor: pointer;
  position: absolute;
  left: calc(5vw - var(--iconWidth)/2);
  bottom: calc((100vh - var(--navBarHeight))/2 - var(--iconHeight)/2);
}

.appLayoutGroupEdit__groupLayoutContent {
  background-color: #eee;
  overflow-y: scroll;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}