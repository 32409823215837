.menuOwner__ownerMenuSegment {
  background-color: #fff;
  vertical-align: middle;
  font-size: 26px;
  cursor: pointer;
  height: 25vh;
  position: relative;
  border: solid 2px #ddd;
  border-radius: 4px;
  transition: all 0.3s;
}

.menuOwner__ownerMenuSegment:hover {
  background-color: #ddd;
  border: solid 2px orange;
}

.menuOwner__ownerMenuSegmentInner {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  max-width: 200px; /*widthの指定は必須*/
  max-height: 25px; /*heightの指定は必須*/
  position: absolute;
  margin: auto; /*上下左右中央に*/
}
