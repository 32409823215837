.blogs__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.blogs__appGridContent {
  min-height:90vh;
}

.blogs__latest-posts {
  padding: 2rem 0 6rem;
}