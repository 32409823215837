.mediaCardCart__mediaCardCard {
  grid-template-columns: 140px 1fr 80px;
  grid-template-rows: 2.5rem 2rem 2rem 2rem 2rem;
}

.mediaCardCart__mediaCardHeader {
  line-height: 2rem;
  padding: 5px;
  border-bottom: solid 1px #E5E9EC;
}

.mediaCardCart__mediaCardHeader > h4 {
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mediaCardCart__mediaCardHeader > i {
  margin-top: -.5rem !important;
}

.mediaCardCart__mediaCardItemTitle {
  padding-left: .5rem;
  color: #707070;
  font-size: .875rem;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mediaCardCart__mediaCardColumn {
  text-align: right;
  padding-right: 10px;
}

.mediaCardCart__mediaCardItemValue {
  font-size: 1rem;
  line-height: 2rem;
}



.mediaCardCart__mediaCardCard {
  grid-template-columns: 140px 1fr 80px;
  grid-template-rows: 2.5rem 2rem 2rem 2rem 2rem;
}

.mediaCardCart__mediaCardColumn {
  text-align: right;
  padding-right: 10px;
}
.mediaCardCart__mediaCardItemTitle {
  padding-left: .5rem;
  color: #707070;
  font-size: .875rem;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mediaCardCart__mediaCardItemValue {
  font-size: 1rem;
  line-height: 2rem;
}
.mediaCardCart__mediaCardItemValueSearch {
  font-size: 1.5rem;
  line-height: 2rem;
}
.mediaCardCart__mediaCardHeader {
  line-height: 2rem;
  padding: 5px;
  border-bottom: solid 1px #E5E9EC;
}
.mediaCardCart__mediaCardHeaderSearch {
  line-height: 2rem;
  padding: 5px;
  text-align: left;
}
.mediaCardCart__mediaCardAddressSearch {
  font-size: 0.9rem;
  color: #707070;
  line-height: 2rem;
  padding: 0px 5px;
  text-align: left;
}
.mediaCardCart__mediaCardHeader > h4 {
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mediaCardCart__mediaCardHeader > i {
  margin-top: -.5rem !important;
}
.mediaCardCart__accountImageCircle {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.mediaCardCart__accountImageCircle img {
  border-radius: 50%;
  border: solid 1px #ddd;
  object-fit: cover;
}


