.mapPlaceSearchMarker__mapInfo {
  color: black;
  width: 150px;
  z-index: 1000;
  position: absolute;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #000;
  padding: 0.5rem;
  bottom: 25px;
  left: -75px;
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-25px);
  white-space: normal;
}

.mapPlaceSearchMarker__icon {
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  left: -25.955px;
  margin: 0px;
  z-index: 500;
}
