.mapSearchDisplay__mediaCardDefault {
    background-color: white;
    border-radius: .5rem;
    border: solid 2px #ddd;
    cursor: pointer;
    margin-bottom: 1.5rem;
    overflow: hidden;
  }
  
.mapSearchDisplay__mediaCardSelected {
    background-color: white;
    border-radius: .5rem;
    border: solid 2px orange;
    cursor: pointer;
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.mapSearchDisplay__pagenationWrapper{
  display: flex;
  justify-content: center;
}