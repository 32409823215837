.singinOwner__loginFormContainer {
  max-width: 400px;
  margin: auto;
  padding: 20px;
}

.singinOwner__appLayoutMessage {
  /* text-align: center; */
  bottom: 100px;
  position: absolute !important;
  z-index: 2;
  width: 60vw;
  left: 20vw;
}
