.doc-req__navBarGridRow {
  height: 55px;
  border-bottom: 53px #3c3c3c solid;
}

.doc-req__appGridContent {
  min-height:90vh;
}

.doc-req__appGridFooter {
  position: relative;
}